<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="床位监测管理系统" describe="床位监测管理系统通过在需要监护生命体征的患者病床上安装智能床垫，在不直接接触患者身体的情况下，测量、传输患者的心率、呼吸和离床、翻身状态等数据。" describe2="通过高灵敏度压电薄膜传感器，捕捉到人体由心跳和呼吸引起的微小震动，获取准确的行为（在床和离床）与健康（心冲击图和呼吸波形图）数据。通过一阶滤波算法计算出用户的心率、呼吸率、心率变异性、睡眠分析数据，结合这些数据提供实时报警功能，并给出健康指导建议。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="introduction-content">
        <img src="@/assets/product/monitor1.png" class="img1" alt="">
        <img src="https://static.zhongjiadata.com/images/20250228/c95f16ab380d4dc2a1b43c5f6e192d58.png" class="img2" alt="">
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: 'SmartWard',
  components: {
    Banner
  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #fff;
  }
  .introduction-content {
    width: 1349px;
    margin: 0 auto;
    .img1 {
      width: 1350px;
      height: 509px;
      margin-bottom: 180px;
    }
    .img2 {
      width: 1349px;
      height: 1008px;
    }
  }
}
</style>
